import React from "react"
import Moment from "react-moment"
import styled from "@emotion/styled"

const calendarPreview = ({post, color, text }) => {

  const styleColor = color ? color : '#008100'
  const textColor = text ? text : 'white'

  const EventItem = styled.a`
    border-bottom: 1px solid ${textColor};
    padding: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @media (max-width: 640px) {
      & > *:not(:last-of-type) {
        line-height: 1.6;
        margin-bottom: 10px;
      }
    }
            
    @media (min-width: 641px) {
      flex-direction: row;
      
      &:hover {
        background: ${styleColor};
        
        p {
          color: #fff;
        }
      }
    }
  `
  const EventName = styled.p`
    margin: 0;
    flex: 1;
    color: ${textColor};
    text-align: ${props => (props.textAlign || 'center')};
    flex-grow: ${props => (props.flexGrow ? props.flexGrow : 0)};
         
    @media (min-width: 641px) {
      flex-basis: 200px;
    }
  `

  return (
    <>
    <EventItem
    // className='calendar-preview'
    textAlign='right'
    href={`https://www.facebook.com/${post.id}`}
    target='_blank'
    rel='noopener noreferrer'
    >
      <EventName textAlign='left'>
        <Moment format="YYYY.MM.DD">{post.start_time}</Moment>
      </EventName>
      <EventName flexGrow='1'>{post.name}</EventName>
      <EventName textAlign='right'>{post.place.location !== null ? post.place.location.city : 'T.B.A.'}</EventName>
    </EventItem>
    </>
  )
}

export default calendarPreview
