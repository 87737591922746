import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import CalendarPreview from "../components/calendarPreview"

const Article = styled.article`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`

const AboutPage = ({
   data: {
     contentful,
     facebook
   }
 }) => {

  // define today's date
  const currentDate = new Date()

  return (
    <Layout title={contentful.title}>
      <Article title={contentful.title}>
        {
          facebook.events.data.map((event, index) => {

            // convert facebook's start_time string into event date
            let eventDate = event.start_time.substring(0, 10).split('-')
            let unixEventDate = new Date(eventDate)

            return (
              <CalendarPreview
                key={index}
                post={event}
                text={unixEventDate < currentDate ? 'gray' : ''}
              />
            )
          })
        }
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentful: contentfulPages(type: {eq: "agenda"}) {
      id
      title
    },
    facebook: facebook {
      events {
        data {
          name
          id
          description
          end_time
          start_time
          place {
            name
            location {
              city
            }
          }
        }
      }
    }
  }
`

export default AboutPage
